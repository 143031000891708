<template>
    <div>
        <CwePageHeader :authenticationRequired="false"/>
        
        <div id="MainDashboard">
            
            <div id="WelcomeText">
                <div class="max-page-width">
                    {{ $t('welcome_text') }}
                </div>
            </div>
            
            <div class="max-page-width">
                <h5>{{ $t('my_zbedge_apps') }}</h5>
                <div v-if="usersApps.length==0" class="bottomSpaceDouble">{{
                        $t('no_apps_enabled_for_your_account')
                    }}
                </div>
                <div v-if="usersApps.length>0" class="appsRow row gx-4">
                    <AppListItem v-for="app in usersApps" :key="app.name" v-bind:app="app"/>
                </div>
                
                <h5 v-if="moreApps.length>0">{{ $t('more_apps_to_consider') }}</h5>
                <div v-if="moreApps.length>0" class="appsRow row gx-4">
                    <AppListItem v-for="app in moreApps" :key="app.name" v-bind:app="app"/>
                </div>
            </div>
        
        </div>
    </div>
</template>

<script lang="ts">
import * as Vue from 'vue';
import AppListItem from '../components/AppListItem.vue';
import CwePageHeader from '../components/CwePageHeader.vue';
import AppRedirectService from '../services/AppRedirectService';
import { store } from '../store/store';

export default Vue.defineComponent({
    name: 'DashboardPage',
    components: {
        CwePageHeader,
        AppListItem
    },
    beforeRouteEnter(to, from, next) {
        let apps = store.state.apps;
        var appRedirectExists = store.state.appRedirect && store.state.appRedirect.length > 0;
        var appEnabledCount = 0;
        var appVisibleCount = 0;
        var appEnabledIndex = 0;
        var appRedirectUrl = '';
        var appRedirectKey = '';
        var path = to.redirectedFrom?.query?.path?.toString();

        for (var appIndex = 0; appIndex < apps.length; appIndex++) {
            var app = apps[appIndex];
            if (app.enabled) {
                appEnabledCount++;
                appEnabledIndex = appIndex;

                if(app.visibleOnDashboard) {
                    appVisibleCount++;
                }

                if (store.state.appRedirect == app.key) {
                    appRedirectUrl = app.appRedirectUrl;
                    appRedirectKey = app.key;
                }
            }
        }

        // This is for the auto redirect if only 1 app is enabled
        // OR if there is only 1 visible app, redirect if the path was not set
        if ((appRedirectExists == false && appEnabledCount == 1) 
            || (appEnabledCount > 1 && appVisibleCount == 1 && path === undefined)) {
            let app = apps.find(a => a.enabled && a.visibleOnDashboard);
            appRedirectUrl = app?.appRedirectUrl ?? '';
            appRedirectKey = app?.key ?? '';
        }

        if (appRedirectUrl.length > 0 && appRedirectKey.length > 0) {
            if (appRedirectExists) {
                AppRedirectService.Go(appRedirectUrl, appRedirectKey, false, path);
                return;
            }

            AppRedirectService.Go(appRedirectUrl, appRedirectKey, appVisibleCount > 1, path);
            return;
        }

        next();
    },
    data() {
        return {};
    },
    computed: {
        usersApps() {
            return store.state.apps.filter(app => app.visibleOnDashboard);
        },
        moreApps() {
            return store.state.moreApps;
        }
    },
    methods: {},
    created: function () {
        
    }
});
</script>

<style lang="scss">
#WelcomeText {
    font-weight: $font_weight_bold;
    color: $white;
    text-align: center;
    background: $dark_gray;
    padding: 60px 16% 40px 16%;
    margin-bottom: 40px;
}

h5 {
    margin-bottom: 20px;
    font-size: 1.1rem;
    font-weight: $font_weight_600;
    color: $dark_gray;
}
</style>