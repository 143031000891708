import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withModifiers as _withModifiers, withKeys as _withKeys, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "confirm-id" }
const _hoisted_2 = { class: "shared-page" }
const _hoisted_3 = { class: "centeredContentBox" }
const _hoisted_4 = {
  class: "header",
  role: "heading"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "alertBox textLarge bottomSpaceDouble" }
const _hoisted_7 = { class: "textLarge bottomSpace" }
const _hoisted_8 = { class: "section" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "buttonWrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CwePageHeader = _resolveComponent("CwePageHeader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CwePageHeader, { authenticationRequired: true }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t("redeem_invite_title_confirm")), 1),
        (_ctx.pageErrorMessage)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.pageErrorMessage), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.getVerificationCodeInstructions()), 1),
        _createElementVNode("form", {
          onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitHandler && _ctx.submitHandler(...args)), ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", null, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.phonePin) = $event)),
                maxlength: "6",
                type: "text",
                placeholder: "xxxxxx",
                onKeypress: _cache[1] || (_cache[1] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.submitHandler && _ctx.submitHandler(...args)), ["prevent"]), ["enter"]))
              }, null, 544), [
                [_vModelText, _ctx.phonePin]
              ])
            ])
          ]),
          _createElementVNode("p", {
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.sendVerificationCodeRequest && _ctx.sendVerificationCodeRequest(...args))),
            class: "textLarge bottomSpaceDouble",
            innerHTML: _ctx.getVerificationCodeHtml()
          }, null, 8, _hoisted_9),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("button", {
              class: "button",
              type: "button",
              value: "ValidatePhonePin",
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.submitHandler && _ctx.submitHandler(...args)))
            }, _toDisplayString(_ctx.$t("redeem_invite_validate_phone_button")), 1)
          ])
        ], 32)
      ])
    ])
  ]))
}