import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  id: "FindInvitesPage",
  class: "shared-page"
}
const _hoisted_2 = { class: "centeredContentBox" }
const _hoisted_3 = { class: "back-section" }
const _hoisted_4 = { class: "back-section-inner" }
const _hoisted_5 = { class: "bottomSpaceDouble" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "alertBox textLarge bottomSpaceDouble" }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "textLarge bottomSpace" }
const _hoisted_10 = { class: "section" }
const _hoisted_11 = { class: "label" }
const _hoisted_12 = ["for"]
const _hoisted_13 = {
  key: 0,
  class: "error"
}
const _hoisted_14 = { class: "section bottomSpaceDouble" }
const _hoisted_15 = {
  key: 0,
  class: "error"
}
const _hoisted_16 = { class: "buttonWrap" }
const _hoisted_17 = {
  class: "button",
  type: "submit",
  value: "EmailPhoneFormSubmit"
}
const _hoisted_18 = { key: 2 }
const _hoisted_19 = { class: "textLarge bottomSpace" }
const _hoisted_20 = { class: "section" }
const _hoisted_21 = ["innerHTML"]
const _hoisted_22 = { class: "buttonWrap" }
const _hoisted_23 = {
  class: "button",
  type: "submit",
  value: "ValidatePhonePin"
}
const _hoisted_24 = { key: 3 }
const _hoisted_25 = { class: "textLarge bottomSpace" }
const _hoisted_26 = { id: "matchingInvites" }
const _hoisted_27 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CwePageHeader = _resolveComponent("CwePageHeader")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_PhoneNumberInput = _resolveComponent("PhoneNumberInput")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_CwePageHeader, { authenticationRequired: true }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_ctx.currentStep == 'enterPhonePin' && _ctx.phoneProvided == true)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.back()))
                }, [
                  _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-angle-left" })
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.$t('redeem_invite_title')), 1),
        (_ctx.pageErrorMessage)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.pageErrorMessage), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.currentStep=='collectEmailPhone')
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t('find_invites_email_phone_instructions')), 1),
              _createElementVNode("form", {
                id: "ValidatePhoneForm",
                onSubmit: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.SubmitEmailPhone && _ctx.SubmitEmailPhone(...args)))
              }, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("label", { for: _ctx.email }, _toDisplayString(_ctx.$t('email_address')), 9, _hoisted_12)
                  ]),
                  _createElementVNode("div", null, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.email) = $event)),
                      maxlength: "100",
                      type: "email"
                    }, null, 512), [
                      [_vModelText, _ctx.email]
                    ])
                  ]),
                  (_ctx.emailError.length)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.emailError), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_14, [
                  _createVNode(_component_PhoneNumberInput, {
                    phoneNumberLabel: this.$t('mobile_number'),
                    phoneNumberExampleLabel: this.$t('phone_number_example'),
                    noMatchingCountriesLabel: this.$t('no_matching_countries'),
                    phoneCountryCodes: this.phoneCountryCodes,
                    phoneCountryCode: this.selectedCountryCode,
                    phoneNumber: this.phone,
                    onUpdatePhoneCountryCode: _cache[2] || (_cache[2] = ($event: any) => (_ctx.phoneCountryCodeChanged($event))),
                    onUpdatePhoneNumber: _cache[3] || (_cache[3] = ($event: any) => (_ctx.phoneNumberChanged($event))),
                    onUpdatePhoneNumberError: _cache[4] || (_cache[4] = ($event: any) => (_ctx.phoneNumberErrorChanged($event)))
                  }, null, 8, ["phoneNumberLabel", "phoneNumberExampleLabel", "noMatchingCountriesLabel", "phoneCountryCodes", "phoneCountryCode", "phoneNumber"]),
                  (_ctx.phoneError.length)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(_ctx.phoneError), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("button", _hoisted_17, _toDisplayString(_ctx.$t('submit')), 1)
                ])
              ], 32)
            ]))
          : _createCommentVNode("", true),
        (_ctx.currentStep=='enterPhonePin')
          ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
              _createElementVNode("p", _hoisted_19, _toDisplayString(_ctx.getVerificationCodeInstructions()), 1),
              _createElementVNode("form", {
                id: "ValidatePhoneForm",
                onSubmit: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.ValidatePhonePin && _ctx.ValidatePhonePin(...args)))
              }, [
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("div", null, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.phonePin) = $event)),
                      maxlength: "6",
                      type: "text",
                      placeholder: "xxxxxx"
                    }, null, 512), [
                      [_vModelText, _ctx.phonePin]
                    ])
                  ])
                ]),
                _createElementVNode("p", {
                  onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.resendInvite && _ctx.resendInvite(...args))),
                  class: "textLarge bottomSpaceDouble",
                  innerHTML: _ctx.getVerificationCodeHtml()
                }, null, 8, _hoisted_21),
                _createElementVNode("div", _hoisted_22, [
                  _createElementVNode("button", _hoisted_23, _toDisplayString(_ctx.$t('redeem_invite_validate_phone_button')), 1)
                ])
              ], 32)
            ]))
          : _createCommentVNode("", true),
        (_ctx.currentStep=='chooseInvite')
          ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
              _createElementVNode("p", _hoisted_25, _toDisplayString(_ctx.$t('find_invites_choose_invite_instructions')), 1),
              _createElementVNode("ul", _hoisted_26, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pendingInvites, (invite) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: invite.inviteID,
                    onClick: ($event: any) => (_ctx.LoadInvite(invite))
                  }, [
                    _createElementVNode("b", null, _toDisplayString(_ctx.FormatClientName(invite.clientName)), 1),
                    _createTextVNode(_toDisplayString(_ctx.FormatApplicationNames(invite.applicationNames)), 1)
                  ], 8, _hoisted_27))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}