<template>
  <div class="phoneNumberLabelRow">
    <div class="phoneNumberLabelColumn">
      <div class="label">
        <label :for="phoneNumber">{{ phoneNumberLabel }}</label>
      </div>
    </div>
    <div class="phoneNumberExampleColumn">
      <div class="label">
        <label class="phoneNumberExample" :for="phoneNumber">{{ phoneNumberExampleLabel }}: {{ phoneNumberExample }}</label>
      </div>
    </div>
  </div>

  <div class="phoneNumberRow">
    <div class="phoneCountryCodeColumn">
      <div class="phoneCountryCode">
        <v-select class="phoneCountryCodeSelect"
                  v-model="selectedCountryCode"
                  :options="phoneCountryCodes"
                  label="Code"
                  v-on:option:selected="phoneCountryCodeChanged">
          <!-- eslint-disable-next-line vue/no-unused-vars  -->
          <template #no-options="{ search, searching, loading }">
            {{ noMatchingCountriesLabel }}
          </template>
          <template #selected-option="{ Code, Ext }">
            <img v-if="Code" :src="getFlag(Code)" />&nbsp;
            <span v-if="Code" class="countryCode">{{ Code }}</span>
            <template v-if="Ext">
              <span class="ext">({{ Ext }})</span>
            </template>
          </template>
          <template v-slot:option="option">
            <img :src="getFlag(option.Code)" />&nbsp;
            <span class="countryCode">{{ option.Code }}</span>
            <template v-if="option.Ext != ''">
              <span class="ext">({{ option.Ext }})</span>
            </template>
          </template>
        </v-select>
      </div>
    </div>

    <div class="phoneNumberColumn">
      <input id="phoneNumber"
             v-bind:value="phoneNumber"
             v-bind:placeholder="placeholder"
             v-on:input="phoneChanged($event.target.value)" />
    </div>
  </div>
</template>

<script lang="ts">
  import { CountryCode } from '@/types/phoneCountryCodes';
  import parsePhoneNumber, { CountryCode as CountryCodeEnum, getExampleNumber, isSupportedCountry } from 'libphonenumber-js';
  import GetCountryFlagUrl from '../services/CountryFlag';
  import examples from 'libphonenumber-js/mobile/examples';
  import * as Vue from 'vue';

  export default Vue.defineComponent({
    name: 'PhoneNumberInput',
    props: {
      phoneNumberLabel: {
        type: String,
        required: true,
        default: "Phone Number",
      },
      phoneNumberExampleLabel: {
        type: String,
        required: true,
        default: "Example",
      },
      noMatchingCountriesLabel: {
        type: String,
        required: true,
        default: "Sorry, no matching countries.",
      },
      phoneCountryCodes: {
        type: Array,
        required: true,
        default: () => new Array<CountryCode>()
      },
      phoneCountryCode: {
        type: CountryCode,
        required: false,
        default: undefined,
      },
      phoneNumber: {
        type: String,
        required: true,
        default: "",
      },
    },
    data() {
      return {
        phone: '',
        phoneNumberExample: '',
        placeholder: '',
        selectedCountryCode: this.phoneCountryCode,
      }
    },
    watch: {
      phoneCountryCode: function (newVal, oldVal) { // watch it
        console.log('Prop changed: ', newVal, ' | was: ', oldVal);
        this.selectedCountryCode = newVal ?? new CountryCode();
      }
    },
    mounted: async function () {
      var tempCountryCodes = JSON.parse(JSON.stringify(this.phoneCountryCodes)) as Array<CountryCode>;

      for (var countryCodeIndex = 0; countryCodeIndex < tempCountryCodes.length; countryCodeIndex++) {
        var phoneCountryCode = this.phoneCountryCodes[countryCodeIndex] as CountryCode;

        if (phoneCountryCode && !isSupportedCountry(phoneCountryCode.Code))
          this.phoneCountryCodes.splice(countryCodeIndex, 1);
      }
    },
    created: async function () {
      var loadCounter = 0;

      while (this.selectedCountryCode == undefined) {
        await new Promise(resolve => {
          setTimeout(() => {
            resolve(this.selectedCountryCode != undefined && this.selectedCountryCode.Code.length > 0)
          }, 100);
        }).finally(() => {
          if (this.selectedCountryCode) {
            var elements = document.getElementsByClassName("vs__clear");

            if (elements.length > 0) {
              var clearButton = elements[0];
              clearButton.removeEventListener('mouseup', this.clearButtonSelected);
              clearButton.addEventListener('mouseup', this.clearButtonSelected);
            }

            if (this.selectedCountryCode && this.phone.length > 0) {
              this.phone = this.formatPhoneNumber(this.selectedCountryCode, this.phone);
            }

            this.setPhonePlaceholder(this.selectedCountryCode);
          }

          if (loadCounter == 10)
            return;

          loadCounter++;
        });
      }    
    },
    methods: {
      getFlag(countryCode: string) {
        return GetCountryFlagUrl(countryCode);
      },
      clearButtonSelected() {
        this.selectedCountryCode = undefined;

        if (this.phoneNumber == '') {
          this.placeholder = '';
          this.phoneNumberExample = '';
          this.$emit('updatePhoneNumberError', '');
        }

        this.$emit('updatePhoneCountryCode', new CountryCode());
      },
      phoneCountryCodeChanged(countryCode: CountryCode) {
        this.selectedCountryCode = countryCode;

        var elements = document.getElementsByClassName("vs__clear");

        if (elements.length > 0) {
          var clearButton = elements[0];
          clearButton.removeEventListener('click', this.clearButtonSelected, false);
          clearButton.addEventListener('click', this.clearButtonSelected, false);
        }

        if (this.selectedCountryCode && this.phone.length > 0) {
          let formattedPhoneNumber = this.formatPhoneNumber(this.selectedCountryCode, this.phone);

          if (formattedPhoneNumber != this.phone) {
            this.phone = formattedPhoneNumber;
            this.$emit('updatePhoneNumber', formattedPhoneNumber);
          }
        }

        this.setPhonePlaceholder(countryCode);
        this.$emit('updatePhoneCountryCode', countryCode);
      },
      phoneChanged(phone: any) {
        if (this.phone.length > 0 && phone.length <= 0 && this.selectedCountryCode == undefined) {
          this.setPhonePlaceholder(new CountryCode());
        }

        if (this.selectedCountryCode) {
          phone = this.formatPhoneNumber(this.selectedCountryCode, phone);
        }

        this.phone = phone;
        this.$emit('updatePhoneNumber', phone);
      },
      setPhonePlaceholder(countryCode: CountryCode) {
        if (JSON.stringify(countryCode) == JSON.stringify(new CountryCode)) {
          this.placeholder = '';
          return;
        }

        var countryCodeEnum = countryCode.Code as CountryCodeEnum;
        const examplePhoneNumber = getExampleNumber(countryCodeEnum, examples);

        if (examplePhoneNumber) {
          var formatNational = examplePhoneNumber.formatNational();

          if (formatNational && formatNational.length > 0) {
            var phoneNumberExample = '';
            var placeholder = '';
            
            for (var placeHolderIndex = 0; placeHolderIndex < formatNational.length; placeHolderIndex++) {
              var value = formatNational[placeHolderIndex];

              if (value == ' ' || value == '-') {
                phoneNumberExample += ' ';
                placeholder += ' ';
              }
              else if (value == '(' || value == ')') {
                phoneNumberExample += '';
                placeholder += '';
              }
              else {
                phoneNumberExample += value;
                placeholder += "x";
              }
            }

            this.phoneNumberExample = phoneNumberExample;
            this.placeholder = placeholder;
          }
        }
      },
      formatPhoneNumber(countryCode: CountryCode, phoneNumber: string) {
        if (JSON.stringify(countryCode) == JSON.stringify(new CountryCode))
          return phoneNumber;

        var countryCodeEnum = countryCode.Code as CountryCodeEnum;
        let fullNumber = '+' + countryCode.Ext + phoneNumber;
        let parsedPhoneNumber = parsePhoneNumber(fullNumber, countryCodeEnum);

        return parsedPhoneNumber
          ? parsedPhoneNumber.formatNational()
          : phoneNumber;
      }
    },
  })
</script>

<style lang="scss">
  .phoneNumberLabelRow {
    display: flex;
    max-width: 350px;
  }

  .phoneNumberLabelRow > .phoneNumberLabelColumn {
    flex: 1 1 1;
    min-width: 150px;
    max-width: 175px;
    margin-right: 4px;
  }

  .phoneNumberLabelRow > .phoneNumberLabelColumn {
    flex: 1 1 2;
  }

  .phoneNumberExample {
    color: rgba(0, 0, 0, 0.4);
    font-size: 12px;
  }

  .phoneNumberRow {
    display: flex;
    max-width: 350px;
  }

  .phoneNumberRow > .phoneCountryCodeColumn {
    flex: 1 1 1;
    min-width: 125px;
    max-width: 175px;
    margin-right: 4px;

    img {
      max-width: 24px;
    }

    .countryCode {
      color: $primary_text;
    }

    .ext {
      font-size: 0.8rem;
      color: $faint_text;
      padding-left: 2px;
    }

    .vs__search, .vs__search:focus {
      padding: 0;
    }

    div[id*='vs'][id$='__combobox'] {
      min-height: 30.46px;
      max-height: 30.46px;
      font-size: 16px;
      padding: 0px 2px;
      cursor: pointer;

      .vs__selected-options {
        overflow: hidden;
      }

      .vs__selected {
        white-space: nowrap;
        padding: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        color: $faint_text;
        margin: 1px 0;
        min-width: 100%;
      }
    }

    .vs__dropdown-menu {
      overflow-x: hidden;

      .vs__dropdown-option {
        padding: 2px;
        font-size: 0.9rem;
      }
    }

    .vs__actions {
      padding: 0 2px;

      button.vs__clear {
        min-width: 0;
        cursor: pointer !important;
        margin-top: -2px;

        &:hover {
          background-color: initial;
          color: initial;
          border-color: initial;
        }
        
        &:disabled {
          background-color: initial;
          color: initial;
          border-color: initial;
        }
      }

      .vs__open-indicator {
        min-width: 15px;
      }
    }
  }

  .phoneNumberRow > .phoneNumberColumn {
    flex: 1 1 2;

    @media screen and ( max-width: 500px ) {
      
      input {  
        max-width: 150px;
      }
    }
  }
</style>
