<template>
  <div>
    <CwePageHeader :authenticationRequired="true" />

    <div id="LinkFederatedUserPage" class="shared-page">
      <div class="centeredContentBox">
        <h3 class="bottomSpaceDouble">{{ $t('link_account_title') }}</h3>

        <div>
          <p class="textLarge bottomSpace">{{ $t('link_account_instructions') }}</p>

          <div id="proceedToSignUpWrap" class="buttonWrap bottomSpaceDouble">
            <a :href="SignUp()">
              <button class="button" type="button" value="LinkAccount">{{ $t('link_account_button') }}</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script lang="ts">
  import * as Vue from 'vue';
  import CwePageHeader from '../components/CwePageHeader.vue';
  import FixProxyUrl from '../services/FixProxyUrl';
  import GetBrowserLocale from '../services/GetBrowserLocale';
  import { localizationModule } from '../store/localizationModule';
  import { store } from '@/store/store';

  interface LinkFederatedUserData {
    inviteId: string | undefined;
    inviteCode: string | undefined;
    countryCode: string | undefined;
  }

  export default Vue.defineComponent({
    name: 'RedeemInvitePage',
    components: {
      CwePageHeader,
    },
    data(): LinkFederatedUserData {
      return {
        inviteId: '',
        inviteCode: '',
        countryCode: ''
      }
    },
    methods: {
      SignUp() {
        let signupURL = `/signup?inviteId=${this.inviteId}&inviteCode=${this.inviteCode}&countryCode=${this.countryCode}&locale=${store.state.browserLocale}`;
        return FixProxyUrl(signupURL);
      },
      loadBrowserLocale: async function () {
        store.state.browserLocale = GetBrowserLocale();
      }
    },
    async mounted() {
      if (!localizationModule.loaded) {
        if (!store.state.browserLocale) {
          await this.loadBrowserLocale();
        }

        await localizationModule.loadLocalizedStrings();
      }

      this.inviteId = this.$route.query.id?.toString();
      this.inviteCode = this.$route.query.code?.toString();
      this.countryCode = this.$route.query.countryCode?.toString();
    }
  })
</script>

<style lang="scss">
  .buttonWrap {
    margin-bottom: $standard_space;
  }

  button {
    min-width: 300px;
  }

  #LinkFederatedUserPage {
    p {
      text-align: left;
    }

    .alertBox {
      text-align: center
    }
  }

  #createAccountForm {
    margin: auto;
    max-width: 300px;

    button {
      width: 100%;
    }

    input {
      width: 100%;
    }
  }
</style>
