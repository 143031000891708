import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "AppListItem col" }
const _hoisted_2 = { class: "appLogo" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { class: "appName" }
const _hoisted_5 = { class: "appDesc" }
const _hoisted_6 = {
  key: 0,
  "data-testid": "{{ app.name.replace(' ', '_') }}"
}
const _hoisted_7 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "col-content",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.LoadApp()))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("img", {
          src: _ctx.app.iconUrl,
          alt: _ctx.pic
        }, null, 8, _hoisted_3)
      ]),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.app.name), 1),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.app.description), 1),
      (_ctx.app.enabled)
        ? (_openBlock(), _createElementBlock("a", _hoisted_6, _toDisplayString(_ctx.$t('launch')) + " >", 1))
        : (_openBlock(), _createElementBlock("a", _hoisted_7, _toDisplayString(_ctx.$t('learn_more')) + " >", 1))
    ])
  ]))
}