import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "MainDashboard" }
const _hoisted_2 = { id: "WelcomeText" }
const _hoisted_3 = { class: "max-page-width" }
const _hoisted_4 = { class: "max-page-width" }
const _hoisted_5 = {
  key: 0,
  class: "bottomSpaceDouble"
}
const _hoisted_6 = {
  key: 1,
  class: "appsRow row gx-4"
}
const _hoisted_7 = { key: 2 }
const _hoisted_8 = {
  key: 3,
  class: "appsRow row gx-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CwePageHeader = _resolveComponent("CwePageHeader")!
  const _component_AppListItem = _resolveComponent("AppListItem")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_CwePageHeader, { authenticationRequired: false }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('welcome_text')), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h5", null, _toDisplayString(_ctx.$t('my_zbedge_apps')), 1),
        (_ctx.usersApps.length==0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t('no_apps_enabled_for_your_account')), 1))
          : _createCommentVNode("", true),
        (_ctx.usersApps.length>0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.usersApps, (app) => {
                return (_openBlock(), _createBlock(_component_AppListItem, {
                  key: app.name,
                  app: app
                }, null, 8, ["app"]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (_ctx.moreApps.length>0)
          ? (_openBlock(), _createElementBlock("h5", _hoisted_7, _toDisplayString(_ctx.$t('more_apps_to_consider')), 1))
          : _createCommentVNode("", true),
        (_ctx.moreApps.length>0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.moreApps, (app) => {
                return (_openBlock(), _createBlock(_component_AppListItem, {
                  key: app.name,
                  app: app
                }, null, 8, ["app"]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}