import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "phoneNumberLabelRow" }
const _hoisted_2 = { class: "phoneNumberLabelColumn" }
const _hoisted_3 = { class: "label" }
const _hoisted_4 = ["for"]
const _hoisted_5 = { class: "phoneNumberExampleColumn" }
const _hoisted_6 = { class: "label" }
const _hoisted_7 = ["for"]
const _hoisted_8 = { class: "phoneNumberRow" }
const _hoisted_9 = { class: "phoneCountryCodeColumn" }
const _hoisted_10 = { class: "phoneCountryCode" }
const _hoisted_11 = ["src"]
const _hoisted_12 = {
  key: 1,
  class: "countryCode"
}
const _hoisted_13 = {
  key: 2,
  class: "ext"
}
const _hoisted_14 = ["src"]
const _hoisted_15 = { class: "countryCode" }
const _hoisted_16 = {
  key: 0,
  class: "ext"
}
const _hoisted_17 = { class: "phoneNumberColumn" }
const _hoisted_18 = ["value", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("label", { for: _ctx.phoneNumber }, _toDisplayString(_ctx.phoneNumberLabel), 9, _hoisted_4)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("label", {
            class: "phoneNumberExample",
            for: _ctx.phoneNumber
          }, _toDisplayString(_ctx.phoneNumberExampleLabel) + ": " + _toDisplayString(_ctx.phoneNumberExample), 9, _hoisted_7)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_component_v_select, {
            class: "phoneCountryCodeSelect",
            modelValue: _ctx.selectedCountryCode,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedCountryCode) = $event)),
            options: _ctx.phoneCountryCodes,
            label: "Code",
            "onOption:selected": _ctx.phoneCountryCodeChanged
          }, {
            "no-options": _withCtx(({ search, searching, loading }) => [
              _createTextVNode(_toDisplayString(_ctx.noMatchingCountriesLabel), 1)
            ]),
            "selected-option": _withCtx(({ Code, Ext }) => [
              Code
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: _ctx.getFlag(Code)
                  }, null, 8, _hoisted_11))
                : _createCommentVNode("", true),
              _createTextVNode("  "),
              Code
                ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(Code), 1))
                : _createCommentVNode("", true),
              Ext
                ? (_openBlock(), _createElementBlock("span", _hoisted_13, "(" + _toDisplayString(Ext) + ")", 1))
                : _createCommentVNode("", true)
            ]),
            option: _withCtx((option) => [
              _createElementVNode("img", {
                src: _ctx.getFlag(option.Code)
              }, null, 8, _hoisted_14),
              _createTextVNode("  "),
              _createElementVNode("span", _hoisted_15, _toDisplayString(option.Code), 1),
              (option.Ext != '')
                ? (_openBlock(), _createElementBlock("span", _hoisted_16, "(" + _toDisplayString(option.Ext) + ")", 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["modelValue", "options", "onOption:selected"])
        ])
      ]),
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("input", {
          id: "phoneNumber",
          value: _ctx.phoneNumber,
          placeholder: _ctx.placeholder,
          onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.phoneChanged($event.target.value)))
        }, null, 40, _hoisted_18)
      ])
    ])
  ], 64))
}