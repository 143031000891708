<template>
  <header>
    <nav class="max-page-width container navbar navbar-expand-sm navbar-toggleable-sm navbar-light">
      <div class="container-fluid">
        <router-link id="logoWrap" class="navbar-brand" :to="isApp == false ? {name:'Dashboard'} : {}">
          <img src="../../public/img/mymobility-logo-horizontal-white-text.svg" />
        </router-link>

        <button @click="collapsed=!collapsed" class="navbar-toggler" type="button" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" v-if="IsLoggedIn">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div :class="{ collapse: collapsed }" class="navbar-collapse d-sm-inline-flex justify-content-between" v-if="authenticationRequired == false">

          <ul class="navbar-nav flex-grow-1">
            <li v-if="IsLoggedIn" class="nav-item">
              <router-link class="nav-link text-white" :to="{name:'EditProfilePage'}">
                {{FullName}}
              </router-link>
            </li>
            <li v-if="IsLoggedIn" class="nav-item dividerWrap">
              <div class="header-hr">
                |
              </div>
            </li>
            <li v-if="IsLoggedIn" class="nav-item">
              <a class="nav-link text-white" :href="FixProxyAuthUrl('/logout')">{{ $t('sign_out') }}</a>
            </li>
            <li v-if="!IsLoggedIn && authenticationRequired == false" class="nav-item">
              <a class="nav-link text-white" :href="FixProxyAuthUrl('/login')">{{ $t('sign_in') }}</a>
            </li>
          </ul>

        </div>
      </div>
    </nav>
    <div id="HeaderOval"></div>
  </header>
</template>

<script lang="ts">
  import * as Vue from 'vue'
  import FixProxyUrl from '../services/FixProxyUrl'
  import { store } from '../store/store'


  export default Vue.defineComponent({
    name: 'CwePageHeader',
    props: {
      authenticationRequired: {
        type: Boolean,
        default: false
      },
      isApp: {
        type: Boolean,
        default: false
      }
    },
    components: {

    },
    computed: {
      FullName() {
        return store.state.fullName
      },
      IsLoggedIn() {
        return store.state.loggedIn
      }
    },
    data() {
      return {
        collapsed: true,
      }
    },
    methods: {
      FixProxyAuthUrl(path: string) {
        return FixProxyUrl(path);
      }
    }
  })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  header {
    border-bottom: 1px solid #CCC;
    background-image: linear-gradient(95.15deg, #00a79d 0, #007bbc 100%);
    position: relative;
  }

  header nav {
    z-index: 2;
  }

  header nav .container-fluid {
    padding-left: 0px;
  }

  header nav ul {
    justify-content: flex-end
  }

  @media screen and ( max-width: 576px ) {
    header div:not(.collapse) > ul > li.dividerWrap {
      display:none;
    }

    header div:not(.collapse) > ul > li.nav-item {
        text-align: right;
        background: rgba(255,255,255,.1);
        margin-bottom: 8px;
        padding: 2px 12px;
    }
  }

  header #logoWrap {
    font-size: 1.5rem;
  }

  header #logoWrap img {
    width: 125px;
  }

  .navbar-nav {
    padding-top: 10px;
  }

  .navbar-toggler{min-width: auto;}

  .header-hr {
    font-weight: bold;
    color: #c2c2c2;
    text-align: center;
    padding-top: 6px;
  }

  .avatar-mask {
    background-image: linear-gradient(95.15deg, #00a79d 0, #007bbc 100%);
    line-height: 0;
    color: #c2c2c2;
    text-align: center;
    overflow: hidden;
    border: 2px solid #c2c2c2;
    border-radius: 50%;
  }

  .profileLink {
    display: inline-block;
    height: 50px;
  }

  .user-avatar {
    min-width: 37px;
    width: 37px;
    min-height: 37px;
    height: 37px;
    background-color: #5fa9d0;
    color: #ededed;
    border: none;
    display: inline-block;
  }

  #HeaderOval {
    background-image: linear-gradient(95.15deg, #00a79d 0, #007bbc 100%);
    border-radius: 50%;
    position: absolute;
    width: 100%;
    height: 3vw;
    bottom: -1.5vw;
    left: 0;
  }

  .showMobile #logoWrap {
    pointer-events: none;
  }
</style>
