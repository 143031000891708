<template>
  <div class="passwordInput" >
    <input v-model="password" @keyup="$emit('update:modelValue', password);" maxlength="100" :type="passwordInputType" />
    <i @click="toggleShowPassword" :class="toggleShowPasswordClass" />
  </div>
</template>

<script lang="ts">
  import * as Vue from 'vue';

  export default Vue.defineComponent({
    name: 'PasswordInput',
    emits: ['update:modelValue'],
      props: {
        modelValue: {
              type: String,
              required: true,
              default: ''
          },
      },
    data() {
        return {
          password: '',
          passwordInputType: 'password',
          toggleShowPasswordClass: 'fa-solid fa-eye',
      }
    },
    methods: {
      toggleShowPassword() {
        this.passwordInputType = this.passwordInputType == 'password' ? 'text' : 'password';
        this.toggleShowPasswordClass = this.toggleShowPasswordClass == 'fa-solid fa-eye' ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye';
      }
    },
  })
</script>

<style lang="scss">

</style>
