<template>
    <div class="AppListItem col ">
        <div class="col-content" @click="LoadApp()">
            <div class="appLogo">
                <img :src="app.iconUrl" v-bind:alt="pic">
            </div>
            <div class="appName">{{ app.name }}</div>
            <div class="appDesc">{{ app.description }}</div>
            <a v-if="app.enabled" data-testid="{{ app.name.replace(' ', '_') }}">{{ $t('launch') }} &#62;</a>
            <a v-else>{{ $t('learn_more') }} &#62;</a>
        </div>
    </div>
</template>

<script lang="ts">
import AppRedirectService from '@/services/AppRedirectService';
import * as Vue from 'vue';

export default Vue.defineComponent({
    name: 'AppListItem',
    props: {
        app: Object
    },
    methods: {
        LoadApp() {
			const openInNewTab = this?.$route?.query?.sameWindow!="true";
			AppRedirectService.Go(this.app?.appRedirectUrl ?? '', this.app?.key ?? '', openInNewTab);
        }
    }
});
</script>


<style lang="scss">
.AppListItem {
    display: inline-block;
    margin: 0 0 40px 0;
    flex: 0 0 auto;
    width: 33.33%;
    
    @media screen and (max-width: 1000px) {
        width: 50%;
    }
    
    @media screen and (max-width: 700px) {
        width: 100%;
    }
    
    .col-content {
        width: 100%;
        background: #fff;
        border-radius: $element_border_radius;
        padding: 10px;
        padding-left: 20%;
        border: 2px solid #fff;
        cursor: pointer;
        min-height: 120px;
        position: relative;
        padding-bottom: 2.0rem;
        
        a {
            font-size: 0.9rem;
            position: absolute;
            bottom: 5px;
            right: 10px;
            color: $primary_color;
            font-weight: bold;
        }
        
        .appLogo {
            position: absolute;
            top: 15px;
            left: 10px;
            width: 20%;
            text-align: center;
            
            img {
                max-width: 100%;
            }
        }
    }
    
    .col-content:hover {
        border-color: $primary_color;
    }
    
    .col-content:hover .appName {
        color: $primary_color;
    }
    
    .appName {
        font-weight: bold;
        margin-bottom: 5px;
        padding-left: 20px;
    }
    
    .appDesc {
        font-size: 0.9rem;
        padding-left: 20px;
    }
}
</style>