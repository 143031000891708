<template>
  <div>
    <CwePageHeader :authenticationRequired="true" />

    <div id="RedeemInvitePage" class="shared-page">
      <div class="centeredContentBox">
        <div class="back-section">
          <div class="back-section-inner">
            <a v-if="!phoneVerified && !invalidInvite && !registrationFormShown && phoneWasMissing == true"
               @click="back()">
              <font-awesome-icon icon="fa-solid fa-angle-left"></font-awesome-icon>
            </a>
          </div>
        </div>

        <h3 class="bottomSpaceDouble" v-show="initiated">{{ GetPageTitle() }}</h3>

        <div v-if="pageErrorMessage">
          <p class="alertBox textLarge bottomSpaceDouble">{{ pageErrorMessage }}</p>
        </div>

        <div v-if="!phoneVerified && !invalidInvite && !registrationFormShown" v-show="initiated">
          <p class="textLarge bottomSpace">{{ $t('redeem_invite_validate_phone_instructions', [formattedMaskedPhone]) }}</p>

          <form id="ValidatePhoneForm" @submit="ValidatePhonePin">
            <div class="section">
              <div>
                <input v-model="phonePin" maxlength="6" type="text" placeholder="xxxxxx" />
              </div>
            </div>

            <p @click="resendInvite" class="textLarge bottomSpaceDouble" v-html="$t('redeem_invite_validate_phone_didnt_receive', ['<a href=\'#\'>', '</a>']).replace(',','')"></p>

            <div class="buttonWrap">
              <button class="button" type="submit" value="ValidatePhonePin">{{ $t('redeem_invite_validate_phone_button') }}</button>
            </div>
          </form>
        </div>

        <div v-if="phoneVerified && !invalidInvite && !registrationFormShown" v-show="initiated">

          <p class="textLarge bottomSpaceDouble alertBox"
             v-if="redeemInviteUserTypeMode=='migrating'" v-html="$t('redeem_invite_body_migrated_migrate')">
          </p>

          <div class="buttonWrap bottomSpaceDouble" v-if="redeemInviteUserTypeMode=='migrating' && allowRegister">
            <a @click="Register()">
              <button class="button" type="button" value="Register">{{ $t('redeem_invite_register_migrated_button') }}</button>
            </a>
          </div>

          <p class="textLarge bottomSpaceDouble"
             v-if="redeemInviteUserTypeMode=='migrating' && allowRegister" v-html="$t('redeem_invite_body_migrated_login')">
          </p>

          <p class="textLarge bottomSpaceDouble"
             v-if="redeemInviteUserTypeMode=='optivu'">
            {{ $t('redeem_invite_body_optivu') }}
          </p>

          <p class="textLarge bottomSpaceDouble"
             v-if="redeemInviteUserTypeMode=='zbemployees'">
            {{ $t('redeem_invite_body_zb_employees') }}
          </p>

          <p class="textLarge bottomSpaceDouble"
             v-if="redeemInviteUserTypeMode=='default'">
            {{ $t('redeem_invite_body_login') }}
          </p>

          <div class="buttonWrap bottomSpaceDouble">
            <a :href="Login(true)">
              <button class="button" type="button" value="Login">{{ $t('redeem_invite_login_button') }}</button>
            </a>
          </div>

          <p class="textLarge bottomSpaceDouble"
             v-if="redeemInviteUserTypeMode=='default' && allowRegister">
            {{ $t('redeem_invite_body_register') }}
          </p>

          <div class="buttonWrap" v-if="redeemInviteUserTypeMode!='migrating' && allowRegister">
            <a @click="Register()">
              <button class="button" type="button" value="Register">{{ $t('redeem_invite_register_button') }}</button>
            </a>
          </div>

        </div>

        <div v-if="registrationFormShown && !invalidInvite" v-show="initiated">

          <div v-if="registrationSuccessShown">
            <p class="textLarge bottomSpaceDouble center">{{ $t('redeem_invite_register_success') }}</p>
          </div>

          <div v-if="!registrationSuccessShown">
            <p class="textLarge bottomSpaceDouble">{{ $t('redeem_invite_register_body') }}</p>

            <form id="createAccountForm" @submit="SubmitPassword">
              <div class="section">
                <div class="label">
                  <label :for="password">{{ $t('redeem_invite_password') }}</label>
                </div>

                <div>
                  <password-input v-model:modelValue="password" />
                </div>

                <div class="error" v-if="passwordError.length">{{ passwordError }}</div>
              </div>

              <div class="section">
                <div class="label">
                  <label :for="confirmPassword">{{ $t('redeem_invite_confirm_password') }}</label>
                </div>

                <div>
                  <password-input v-model:modelValue="confirmPassword" />
                </div>

                <div class="error" v-if="confirmPasswordError.length">{{ confirmPasswordError }}</div>
              </div>

              <div v-if="maskedPhone == null || maskedPhone?.length == 0 || phoneFromRegistration == true" class="section bottomSpaceDouble">
                <PhoneNumberInput :phoneNumberLabel="this.$t('mobile_number')"
                                  :phoneNumberExampleLabel="this.$t('phone_number_example')"
                                  :noMatchingCountriesLabel="this.$t('no_matching_countries')"
                                  :phoneCountryCodes="this.phoneCountryCodes"
                                  :phoneCountryCode="this.selectedCountryCode"
                                  :phoneNumber="this.phone"
                                  @updatePhoneCountryCode="phoneCountryCodeChanged($event)"
                                  @updatePhoneNumber="phoneNumberChanged($event)"
                                  @updatePhoneNumberError="phoneNumberErrorChanged($event)" />

                <div class="error" v-if="phoneError.length">{{ phoneError }}</div>
              </div>

              <div class="buttonWrap">
                <button class="button" type="submit" value="Save Password">{{ $t('redeem_invite_save_password_button') }}</button>
              </div>
            </form>
          </div>
        </div>

        <div id="proceedToLoginWrap" class="buttonWrap bottomSpaceDouble" v-if="showProceedToLogin">
          <a :href="Login(false)">
            <button class="button" type="button" value="Login">{{ $t('redeem_invite_proceed_to_login_button') }}</button>
          </a>
        </div>

      </div>
    </div>
  </div>

</template>

<script lang="ts">
  import axios from 'axios';
  import * as Vue from 'vue';
  import { ApiRoutes } from '../api/ApiRoutes';
  import CwePageHeader from '../components/CwePageHeader.vue';
  import { IStandardResponse } from '../models/StandardResponse';
  import ErrorUrlFromStatusCode from '../services/ErrorUrlFromStatusCode';
  import FixProxyUrl from '../services/FixProxyUrl';
  import FormatPhoneNumber from '../services/FormatPhoneNumber';
  import WaitForAppDependencies from '../services/AppDependencyService';
  import { store } from '../store/store';
  import PhoneNumberInput from '../components/PhoneNumberInput.vue';
  import PasswordInput from '../components/PasswordInput.vue';
  import { CountryCode, PhoneCountryCodes } from '@/types/phoneCountryCodes';
  import parsePhoneNumber, { CountryCode as CountryCodeEnum, validatePhoneNumberLength, ValidatePhoneNumberLengthResult } from 'libphonenumber-js';

  interface IErrorKeys {
    [key: string]: string | undefined;
  }

  var ErrorMessages: IErrorKeys = {
    error_invite_country_code_must_match: 'error_invite_country_code_must_match',
    error_invite_account_type_must_match: 'error_invite_account_type_must_match',
    error_invite_already_redeemed_for_client: 'error_invite_already_redeemed_for_client',
	validate_2fa_pin_invalid: 'validate_2fa_pin_invalid',
    no_apps_enabled_for_your_account: 'no_apps_enabled_for_your_account',
    redeem_invite_invalid: 'redeem_invite_invalid',
	redeem_invite_invalidated: 'redeem_invite_invalidated'
  };

  interface IGetInviteResponse extends IStandardResponse {
    inviteFound: boolean;
    alreadyMatchesUser: boolean;
    alreadyRedeemed: boolean;
    phoneVerified: boolean;
    maskedPhone: string;
    phoneCountryCode: string;
    countryCode: string;
    email: string;
    noAppsEnabled: boolean;
    migratingSourceAppUser: boolean;
    isOptivuTenantUser: boolean;
    phoneFromRegistration: boolean;
    hasZimmerEmail:boolean;
  }

  interface IRegisterInviteResponse extends IStandardResponse {
    inviteFound: boolean;
    alreadyRedeemed: boolean;
    phoneNotVerified: boolean;
  }

  interface RedeemInviteData {
    inviteId: string | undefined;
    inviteCode: string | undefined;
    countryCode: string | undefined;
    phonePin: string | undefined;
    phoneVerified: boolean;
    invalidInvite: boolean;
    initiated: boolean;
    alreadyMatchesUser: boolean;
    noAppsEnabled: boolean;
    pageErrorMessage: string | undefined;
    password: string;
    confirmPassword: string;
    passwordError: string;
    confirmPasswordError: string;
    showProceedToLogin: boolean;
    registrationFormShown: boolean;
    registrationSuccessShown: boolean;
    maskedPhone: string;
    phone: string;
    phoneError: string;
    phoneCountryCodes: CountryCode[],
    phoneCountryCode: string,
    selectedCountryCode: CountryCode | undefined,
    formattedMaskedPhone: string;
    hasZimmerEmail:boolean;
    federatedTenantRequiredForZBEmployees: boolean;
    migratingSourceAppUser: boolean;
    isOptivuTenantUser: boolean;
    redeemInviteUserTypeMode: string;
    allowRegister: boolean;
    phoneOptional: boolean;
    phoneWasMissing: boolean;
    phoneFromRegistration: boolean;
  }

  export default Vue.defineComponent({
    name: 'RedeemInvitePage',
    components: {
      CwePageHeader,
      PhoneNumberInput,
      PasswordInput
    },
    data(): RedeemInviteData {
      return {
        inviteId: '',
        inviteCode: '',
        countryCode: '',
        phonePin: '',
        phoneVerified: false,
        invalidInvite: false,
        initiated: false,
        alreadyMatchesUser: false,
        noAppsEnabled: false,
        pageErrorMessage: undefined,
        password: '',
        confirmPassword: '',
        passwordError: '',
        confirmPasswordError: '',
        showProceedToLogin: false,
        registrationFormShown: false,
        registrationSuccessShown: false,
        maskedPhone: '',
        phone: '',
        phoneError: '',
        phoneCountryCodes: PhoneCountryCodes.sort((a, b) => a.Code > b.Code ? 1 : b.Code > a.Code ? -1 : 0),
        phoneCountryCode: '',
        selectedCountryCode: undefined as CountryCode | undefined, 
        formattedMaskedPhone: '',
        hasZimmerEmail: false,
        federatedTenantRequiredForZBEmployees: false,
        migratingSourceAppUser: false,
        isOptivuTenantUser: false,
        redeemInviteUserTypeMode: '',
        allowRegister: false,
        phoneOptional: false,
        phoneWasMissing: false,
        phoneFromRegistration: false
      }
    },
    methods: {
      back: function () {
        if (!this.phoneVerified && !this.invalidInvite && !this.registrationFormShown && this.phoneFromRegistration) {
          this.clearErrors();
          this.password = '';
          this.confirmPassword = '';
          this.phoneWasMissing = false;
          this.registrationFormShown = true;
        }
      },
      phoneCountryCodeChanged(countryCode: CountryCode) {
        this.phoneCountryCode = countryCode.Ext;
        this.selectedCountryCode = countryCode;
      },
      phoneNumberChanged(phone: any) {
        this.phone = phone;
      },
      phoneNumberErrorChanged(error: any) {
        this.phoneError = error;
      },
      GetPageTitle() {
        if (!this.phoneVerified && !this.noAppsEnabled && this.phoneOptional == true)
          return this.$t('redeem_invite_title_confirm');
        else if (this.redeemInviteUserTypeMode == 'migrating')
          return this.$t('redeem_invite_register_migrated_button');
        
        return this.$t('redeem_invite_title_activate');
      },
      ValidatePhonePin: async function (e: any) {
        e.preventDefault();

        this.clearErrors();

        this.phonePin = this.phonePin?.trim();
                
        if (this.phonePin == null || this.phonePin.length < 6) {
		      this.pageErrorMessage = this.$t('validate_2fa_pin_invalid');
          return;
        }

        if (this.phoneWasMissing == false)
          this.LoadInvite(this.inviteId, this.inviteCode, this.phonePin, false, true);
        else
          this.RegisterInvite();
      },
      resendInvite: function (e: any) {
        e.preventDefault();
        if (e.target.tagName.toLowerCase() == 'a')
          this.LoadInvite(this.inviteId, this.inviteCode, undefined, true, true);
      },
      Login(withInviteParams: boolean) {
        let loginURL = '/login?';
        if (withInviteParams)
          loginURL += `inviteId=${this.inviteId}&inviteCode=${this.inviteCode}`;
        loginURL += `&countryCode=${this.countryCode}&locale=${store.state.browserLocale}`;
        return FixProxyUrl(loginURL);
      },
      Register() {
        this.registrationFormShown = true;
      },
      SubmitPassword: async function (e: any) {
        e.preventDefault();

        this.phone = this.phone?.trim();

        this.clearErrors();

        this.confirmPassword = this.confirmPassword.trim();
        this.password = this.password.trim();
        if (this.password.length < store.state.passwordMinLength)
          this.passwordError = this.$t('error_password_insufficient_length', [store.state.passwordMinLength.toString()]);
        else if (!this.containsRequiredCharacters(this.password))
          this.passwordError = this.$t('error_password_insufficient_complexity');

        if (this.password != this.confirmPassword)
          this.confirmPasswordError = this.$t('error_passwords_dont_match');

        // Ensure if the user selects a country, they have to enter a phone number
        if (this.phoneCountryCode?.length == 0 && !this.selectedCountryCode && this.phone?.length > 0)
          this.phoneError = this.$t('invalid_phone_number');
        // Ensure if the user enters a phone number, they have to select a country
        else if (this.phone?.length == 0 && this.phoneCountryCode?.length > 0 && this.selectedCountryCode)
          this.phoneError = this.$t('invalid_phone_number');

        if (this.selectedCountryCode && this.phone) {
          this.phoneWasMissing = true;
          this.formattedMaskedPhone = FormatPhoneNumber(this.phone, this.phoneCountryCode);

          var countryCodeEnum = this.selectedCountryCode.Code as CountryCodeEnum;
          var parsedPhoneNumber = parsePhoneNumber("+" + this.selectedCountryCode.Ext + this.phone, countryCodeEnum);

          if (parsedPhoneNumber) {
            var validPhoheNumber = parsedPhoneNumber.isValid();
            var validPhoneNumberLength = validatePhoneNumberLength("+" + this.selectedCountryCode.Ext + this.phone, countryCodeEnum) as ValidatePhoneNumberLengthResult;

            if (validPhoheNumber == false || validPhoneNumberLength == 'TOO_LONG')
              this.phoneError = this.$t('invalid_phone_number');
          }
        }

        if (this.confirmPasswordError?.length == 0 && this.passwordError?.length == 0 && this.phoneError?.length == 0) {
          this.RegisterInvite();
        }
      },
      clearErrors: function () {
        this.passwordError = '';
        this.phoneError = '';
        this.confirmPasswordError = '';
        this.pageErrorMessage = '';
      },
      containsRequiredCharacters: function (p: string) {
        var anUpperCase = /[A-Z]/;
        var aLowerCase = /[a-z]/;
        var aNumber = /[0-9]/;
        var aSpecial = /[!|@|#|$|%|^|&|*|(|)|-|_]/;

        var numUpper = 0;
        var numLower = 0;
        var numNums = 0;
        var numSpecials = 0;

        for (var i = 0; i < p.length; i++) {
          if (anUpperCase.test(p[i]))
            numUpper++;
          else if (aLowerCase.test(p[i]))
            numLower++;
          else if (aNumber.test(p[i]))
            numNums++;
          else if (aSpecial.test(p[i]))
            numSpecials++;
        }

        if (numUpper < 1 || numLower < 1 || numNums < 1) // || numSpecials < 1)
          return false;

        return true;
      },
      LoadInvite(inviteId: string | undefined, inviteCode: string | undefined, phonePin: string | undefined, refreshPin: boolean, clearErrors: boolean) {
        this.$store.state.loadingCount++;

        if (clearErrors)
          this.clearErrors();

        axios.get(ApiRoutes.User.GetInvite, { params: { id: inviteId, code: inviteCode, phonePin: phonePin, refreshPin: refreshPin } }).then((response) => {
          const inviteResponse = response?.data as IGetInviteResponse;
          this.populateDataFromInvite(inviteResponse);
        })
          .catch((error) => {
            const inviteResponse = error.response?.data as IGetInviteResponse;

            if (error?.response?.status == 422 && inviteResponse?.errors?.length > 0) {
              this.invalidInvite = !inviteResponse.inviteFound || inviteResponse.alreadyRedeemed;
              this.pageErrorMessage = inviteResponse.errors[0].localizedName;
              this.populateDataFromInvite(inviteResponse);
            } else {
              window.location.href = ErrorUrlFromStatusCode(error?.response?.status);
            }
          })
          .finally(() => {
            this.$store.state.loadingCount--;
          });
      },
      RegisterInvite() {
        this.$store.state.loadingCount++;

        var requestParams = {
          InviteId: this.inviteId,
          InviteCode: this.inviteCode,
          Password: this.password,
          Phone: this.phone,
          PhoneCountryCode: this.phoneCountryCode,
          PhonePin: this.phoneWasMissing ? this.phonePin : null
        }

        axios.post(ApiRoutes.User.RegisterInvite, requestParams).then((response) => {

          const inviteResponse = response.data as IRegisterInviteResponse;

          this.registrationFormShown = true;
          this.registrationSuccessShown = true;
          this.showProceedToLogin = true;
        })
        .catch((error) => {
          const inviteResponse = error.response?.data as IRegisterInviteResponse;

          if ((error?.response?.status == 422 || error?.response?.status == 400) && inviteResponse?.errors?.length > 0) {
            this.invalidInvite = !inviteResponse.inviteFound;
            this.pageErrorMessage = inviteResponse.errors[0].localizedName;
                    
            if (inviteResponse.phoneNotVerified) {
              this.phoneVerified = false;
              this.registrationFormShown = false;                  

              if (this.phoneWasMissing) {
                this.pageErrorMessage = '';
              }
            }

            if (inviteResponse.alreadyRedeemed)
              this.showProceedToLogin = true;
          } else {
            window.location.href = ErrorUrlFromStatusCode(error?.response?.status);
          }
        })
        .finally(() => {
          this.$store.state.loadingCount--;
        });
      },
      populateDataFromInvite(inviteResponse: IGetInviteResponse) {
        this.phoneVerified = inviteResponse.phoneVerified;
        this.countryCode = inviteResponse.countryCode;
        this.maskedPhone = inviteResponse.maskedPhone;
        this.phoneCountryCode = inviteResponse.phoneCountryCode;
        this.formattedMaskedPhone = FormatPhoneNumber(this.maskedPhone, this.phoneCountryCode);
        this.hasZimmerEmail = inviteResponse.hasZimmerEmail;
        this.migratingSourceAppUser = inviteResponse.migratingSourceAppUser;
        this.isOptivuTenantUser = inviteResponse.isOptivuTenantUser;
        this.noAppsEnabled = inviteResponse.noAppsEnabled;
        this.phoneOptional = inviteResponse.maskedPhone?.length == 0;
        this.phoneFromRegistration = inviteResponse.phoneFromRegistration;
        this.alreadyMatchesUser = inviteResponse.alreadyMatchesUser;

        // The phone number is optional, so the user can provide it when asked to provide their password.
        if (inviteResponse.maskedPhone == null || inviteResponse.maskedPhone?.length == 0 || inviteResponse.phoneFromRegistration == true)
          this.registrationFormShown = true;

        if(this.noAppsEnabled)
          this.invalidInvite = true;

        if (inviteResponse.alreadyRedeemed)
          this.showProceedToLogin = true;

        this.allowRegister = false;
        // optivu users and zb emails and already registered emails can't register a new account, only login with their existing accounts
        if(this.isOptivuTenantUser) {
          this.redeemInviteUserTypeMode = 'optivu';
        } else if(this.federatedTenantRequiredForZBEmployees && this.hasZimmerEmail){
          this.redeemInviteUserTypeMode = 'zbemployees';
        } else if (this.migratingSourceAppUser && !this.alreadyMatchesUser) {
          this.redeemInviteUserTypeMode = 'migrating';
          this.allowRegister = true;
        }else{ 
          this.redeemInviteUserTypeMode = 'default';
          if(!this.alreadyMatchesUser) this.allowRegister = true;
        }

        this.initiated = true;
      }
    },
    async mounted() {
      await WaitForAppDependencies();

      this.federatedTenantRequiredForZBEmployees = store.state.federatedTenantRequiredForZBEmployees;

      let errorKey = this.$route.query.errorKey?.toString() ?? '';
      errorKey = errorKey.trim().toLowerCase();

      if (errorKey != null && ErrorMessages[errorKey]) {
        this.pageErrorMessage = this.$t(errorKey);
      }

      this.inviteId = this.$route.query.id?.toString();
      this.inviteCode = this.$route.query.code?.toString();
      this.phonePin = this.$route.query.phonePin?.toString();

      if (!this.inviteId)
        this.inviteId = '0';
      this.inviteId = (this.inviteId + '').replace(/[^a-z0-9]/gi, '').trim();


      this.LoadInvite(this.inviteId, this.inviteCode, this.phonePin, false, false);
    }
  })
</script>

<style lang="scss">
  .buttonWrap {
    margin-bottom: $standard_space;
  }

  #RedeemInvitePage {

    margin-left: auto;
    margin-right: auto;
    padding-left:4%;
    padding-right:4%; 

    p {
      text-align: left;
    }

    .alertBox {
      text-align: center
    }

    button { width:350px; max-width:100%; }

    .centeredContentBox {
      margin-left: auto;
      margin-right: auto;
    }

    .phoneNumberRow > .phoneCountryCodeColumn {
      min-width: 150px;
      max-width: 150px;
    }
  }

  #createAccountForm {
    margin: auto;
    max-width: 300px;

    button {
      width: 100%;
    }

    input {
      width: 100%;
    }
  }
</style>
