import 'bootstrap/dist/css/bootstrap.min.css'
import 'vue-select/dist/vue-select.css';
import { i18n } from '@/plugins/i18n'
import axios from 'axios'
import { createApp } from 'vue'
import VueAxios from 'vue-axios'
import App from './App.vue'
import router from './router'
import { store } from './store/store'
import vSelect from 'vue-select'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faAngleLeft as fasAngleLeft, faTrianglePersonDigging } from '@fortawesome/pro-solid-svg-icons'

library.add(fasAngleLeft, faTrianglePersonDigging);

const app = createApp(App);

app.component('font-awesome-icon', FontAwesomeIcon);

app.component('v-select', vSelect);

app.use(i18n);

//https://www.npmjs.com/package/vue-axios
app.use(VueAxios, axios);


//https://router.vuejs.org/guide/
app.use(router);

app.use(store);

app.mount('#app')