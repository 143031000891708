<template>
    <div class="password">
        <CwePageHeader :authenticationRequired="true"/>
        <div class="shared-page">
            <div class="centeredContentBox">
                <h1 class="header" role="heading">
                    {{ $t('azure_template_heading') }} </h1>
                <form @sumbit.prevent="submitHandler">
                    <div class="section">
                        <div class="label">
                            <label for="email">{{ $t('email_address') }}</label>
                        </div>
                        
                        <div>
                            <input v-model="email" 
                                   maxlength="100"
                                   type="text" 
                                   id="email"
                                   @keypress.enter.prevent="submitHandler"
                            />
                        </div>
                        
                        <div v-if="invalidError" class="error">{{ invalid_email }}</div>
                        <div v-if="requiredError" class="error">{{ required_email }}</div>
                    </div>
                    <div class="buttonWrap">
                        <button class="button" 
                                type="button"
                                @click="submitHandler">
                            {{ $t('submit') }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    
    </div>
</template>

<script lang="ts">
import * as Vue from 'vue';
import CwePageHeader from '../components/CwePageHeader.vue';
import { store, sessionModule } from '../store';
import { SendActivationCodeMode } from '../constants/enums';

export default Vue.defineComponent({
    name: 'ForgotPasswordPage',
    components: {
        CwePageHeader
    },    
    data: function () {
        return {
            email: '',
            requiredError: false,
            invalidError: false
        };
    },
    computed: {
        usersApps() {
            return store.state.apps;
        },
        moreApps() {
            return store.state.moreApps;
        },
        required_email() {
            return this.$t('required_email');
        },
        invalid_email() {
            return this.$t('invalid_email');
        }
    },
    methods: {
        submit(){
            debugger;
        },
        async submitHandler() {
            if (this.onBeforeSubmit()) {
                sessionModule.setInitiatedPasswordResetFlow(true);
                this.$router.push({
                    name: 'ConfirmIdentity',
                    query: {
                        mode: SendActivationCodeMode.change_password,
                        email: this.email
                    }
                });
            }
        },
        onBeforeSubmit(): boolean {
            //check the model
            let form_state = true;
            
            this.requiredError = false;
            this.invalidError = false;
            
            if (this.email == '') {
                form_state = false;
                this.requiredError = true;
                return form_state;
            } else {
                this.requiredError = false;
            }
            let pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi;
            
            if (pattern.test(this.email)) {
                this.invalidError = false;
            } else {
                this.invalidError = true;
                form_state = false;
            }
            return form_state;
        }
    }
});
</script>
