<template>
  <div>
    <CwePageHeader :authenticationRequired="true" />

    <div class="shared-page">
      <div class="centeredContentBox">

        <div id="logoWrap" v-if="showZBEdgeLogo" class="bottomSpaceDouble">ZB<span id="logoEdgeWrap"><span id="logoEWrap">E</span>dge</span>&nbsp;</div>

        <p id="welcomeText" class="bottomSpaceDouble">
          {{ $t('welcome_text') }}
        </p>

        <div v-if="errorDisplayKey" class="alertBox bottomSpace">{{ $t(errorDisplayKey) }}</div>

        <div class="bottomSpaceDouble">
          <select v-model="selectedCountry" @change="CountrySelected()">
            <option v-for="country in countries" v-bind:disabled="country.id === 0" v-bind:key="country.id" v-bind:value="country">{{ country.name }}</option>
          </select>
        </div>

        <div>
          <button class="button" type="button" @click="SubmitForLogin">{{ $t('redeem_invite_validate_phone_button') }}</button>
        </div>
      </div>

    </div>
  </div>
</template>

<script lang="ts">
  import CwePageHeader from '../components/CwePageHeader.vue';
  import { Country, ICountry } from '../models/Country';
  import { countryModule } from '../store/countryModule';
  import { store } from '../store/store';
  import * as Vue from 'vue';
  import { GetSelectedCountryCookie, SetSelectedCountryCookie } from '../services/Cookies/CountryCookie';
  import WaitForAppDependencies from '../services/AppDependencyService';
  import FixProxyUrl from '../services/FixProxyUrl';
  import GetUnixTimestampString from '../services/GetUnixTimestampString';


  interface IErrorKeys {
    [key: string]: string | undefined;
  }

  var ErrorMessages: IErrorKeys = {
    about_url_not_found: 'about_url_not_found',
    countries_not_found: 'countries_not_found',
    localization_strings_not_found: 'localization_strings_not_found',
    prelogin_no_country_selected: 'prelogin_no_country_selected',
    no_matching_user_found: 'no_matching_user_found',
    support_url_not_found: 'support_url_not_found',
    zbedge_app_urls_not_found: 'zbedge_app_urls_not_found',
    no_apps_enabled_for_your_account: 'no_apps_enabled_for_your_account',
	login_migration: 'login_migration', //renamed to login_cwe_redirect in code 
	login_cwe_redirect: 'login_cwe_redirect', 
    optivu_tenant_login_required: 'optivu_tenant_login_required',
    please_complete_registration: 'Please complete registration before continuing.',
    Error_AccountLocked: 'Error_AccountLocked',
    Error_AuthenticationFailure: 'Error_AuthenticationFailure',
    access_denied: 'access_denied',
    Error_CodeValidationExpired:'Error_CodeValidationExpired', 
    Error_DisallowedNetworkAddress: 'Error_DisallowedNetworkAddress',
    Error_InvalidCountry: 'Error_InvalidCountry',
    Error_CodeValidationAttemptsExceeded: 'Error_CodeValidationAttemptsExceeded',
	forbidden: 'insufficient_permissions',
	session_timeout: 'session_timeout'
  };

  function GetLoginPath(selectedCountryCode: string){
      let path = `/login?countryCode=${selectedCountryCode}&locale=${store.state.browserLocale}&timestamp=${GetUnixTimestampString()}`;          

      if(store.state.appRedirect)
        path+='&app='+encodeURIComponent(store.state.appRedirect);
      
      return path; 
    }
  
  export default Vue.defineComponent({
    name: 'PreLoginPage',
    components: {
      CwePageHeader,
    },
    data() {
      return {
        Promises: [
          this.LoadCountries()
        ],
        errorDisplayKey: '',
        appRedirectKey: '',
        networkErrorOccured: false,
        countries: [] as ICountry[],
        selectedCountry: new Country,
        defaultCountry: new Country
      }
    },
    provide() {
      return {

      }
    },
    beforeRouteEnter: async (to, from, next) => {
      let errorKey = to.query.errorKey?.toString() ?? '';
      errorKey = errorKey.trim();

      if (errorKey != null && errorKey.length > 0) {
        next();
        return;
      }

      let noRedirectValue = to.query.noRedirect?.toString() ?? 'false';
      let noRedirect = (noRedirectValue === 'true');

      if (noRedirect) {
        next();
        return;
      }

      const selectedCountryCode = GetSelectedCountryCookie();

      if (selectedCountryCode != null && selectedCountryCode.length > 0) {
        window.location.href=FixProxyUrl(GetLoginPath(selectedCountryCode));
        return;
      }

      next();
    },
    created: async function () {
      await WaitForAppDependencies();
      await this.SetCountries();
      var selectedCountryCode = this.GetSelectedCountryCode();
      this.SetSelectedCountryOnPageLoad(selectedCountryCode);

      let errorKey = this.$route.query.errorKey?.toString() ?? '';
      errorKey = errorKey.trim();
      errorKey = this.GetErrorKeyFromTranslatedString(errorKey);

      if (errorKey != null && (ErrorMessages[errorKey] || ErrorMessages[errorKey.toLowerCase()])) {
        let tmpErrorKey = ErrorMessages[errorKey];
        if (!tmpErrorKey)
          tmpErrorKey = ErrorMessages[errorKey.toLowerCase()] ?? errorKey;
        tmpErrorKey = tmpErrorKey.replace('login_migration', 'login_cwe_redirect')
        this.errorDisplayKey = tmpErrorKey;
      }
      else if (store.state.alwaysShowMigrationMessage) {
        this.errorDisplayKey = 'login_cwe_redirect';
      }

      let noRedirectValue = this.$route.query.noRedirect?.toString() ?? 'false';
      let noRedirect = (noRedirectValue === 'true');

      if (noRedirect === false && selectedCountryCode != null && selectedCountryCode != '') {
        if (this.errorDisplayKey == '' || (store.state.alwaysShowMigrationMessage && this.errorDisplayKey == 'login_cwe_redirect')) {
          window.location.href = FixProxyUrl(GetLoginPath(selectedCountryCode));
          return;
        }
      }
    },
    methods: {
      GetErrorKeyFromTranslatedString(errorKey: string) {
        for (let key in ErrorMessages) {
          let tmpErrorKey = ErrorMessages[key];

          if (!tmpErrorKey)
            tmpErrorKey = ErrorMessages[key.toLowerCase()] ?? key;

          tmpErrorKey = tmpErrorKey.replace('login_migration', 'login_cwe_redirect');

          if (this.$t(tmpErrorKey) == errorKey || this.$t(tmpErrorKey).toLowerCase() == errorKey.toLowerCase()) {
            return tmpErrorKey;
          }
        }

        return errorKey;
      },
      LoadCountries: async function () {
        
        const loadCountriesResult = await countryModule.loadCountries();

        if (loadCountriesResult!=null && !loadCountriesResult.countriesLoadFailed && loadCountriesResult.countries != null) {
          this.countries = loadCountriesResult.countries;
        }
        else {
          if(loadCountriesResult?.networkError)
            this.networkErrorOccured=true;

          if (!window.location.href.includes("countries_load_failed")) {
            throw Error("countries_load_failed")
          }
        }
      },
      SetCountries: async function () {
        store.state.loadingCount++;

        const results = await Promise.all(this.Promises.map(p => p.catch((e: Error) => e)));
        const errors = results.filter(result => result instanceof Error);

        store.state.loadingCount--;

        if (errors.length > 0) {
          const error = (errors.pop() as Error);
          let errorUrl = '/error?errorKey=' + error.message;
          if(this.networkErrorOccured)
           errorUrl+='&networkError=true';
          window.location.href = FixProxyUrl(errorUrl);
          return;
        }

        const defaultCountry = this.countries.filter(country => country.id == "0");

        if (defaultCountry != null && defaultCountry.length > 0 && defaultCountry[0] != null)
          this.defaultCountry = defaultCountry[0]
      },
      SetSelectedCountryOnPageLoad(selectedCountryCode: string) {
        if (selectedCountryCode == null || selectedCountryCode == '') {
          this.selectedCountry = this.defaultCountry;
          return;
        }

        const currentSelectedCountry = this.countries.filter(country => country.abbreviation == selectedCountryCode)

        if (currentSelectedCountry != null && currentSelectedCountry.length > 0 && currentSelectedCountry[0] != null) {
          this.selectedCountry = currentSelectedCountry[0];
          return currentSelectedCountry[0];
        }
      },
      GetSelectedCountryCode() {
        const selectedCountryCode = GetSelectedCountryCookie();
        return selectedCountryCode;
      },
      IsCountrySelected() {
        return this.selectedCountry != null && this.selectedCountry.id != null && this.selectedCountry.id !== '' &&
          this.defaultCountry != null && this.defaultCountry.id != null && this.defaultCountry.id !== '' &&
          this.selectedCountry.id !== this.defaultCountry.id;
      },
      CountrySelected() {
        SetSelectedCountryCookie(this.selectedCountry);

        if (this.IsCountrySelected() == true)
          if (store.state.alwaysShowMigrationMessage) {
            this.errorDisplayKey = 'login_cwe_redirect';
          }
          else {
            this.errorDisplayKey = '';
          }
      },
      SubmitForLogin() {
        if (this.IsCountrySelected() == false)
          this.errorDisplayKey = ErrorMessages.prelogin_no_country_selected as string;
        else {
          let path = GetLoginPath(this.selectedCountry.abbreviation);
          window.location.href = FixProxyUrl(path);
        }
      }
      
    },
  })
</script>

<style lang="scss">
  select, button {
    min-width: 180px;
  }

  #welcomeText {
    font-size: 1rem;
  }
</style>
