import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withKeys as _withKeys } from "vue"

const _hoisted_1 = { class: "password" }
const _hoisted_2 = { class: "shared-page" }
const _hoisted_3 = { class: "centeredContentBox" }
const _hoisted_4 = {
  class: "header",
  role: "heading"
}
const _hoisted_5 = {
  key: 0,
  class: "textLarge bottomSpaceDouble"
}
const _hoisted_6 = {
  key: 1,
  class: "textLarge bottomSpaceDouble"
}
const _hoisted_7 = { key: 2 }
const _hoisted_8 = { class: "alertBox textLarge bottomSpaceDouble" }
const _hoisted_9 = { class: "section" }
const _hoisted_10 = { class: "label" }
const _hoisted_11 = { for: "password" }
const _hoisted_12 = {
  key: 0,
  class: "error"
}
const _hoisted_13 = { class: "section bottomSpaceDouble" }
const _hoisted_14 = { class: "label" }
const _hoisted_15 = { for: "confirmPassword" }
const _hoisted_16 = {
  key: 0,
  class: "error"
}
const _hoisted_17 = { class: "buttonWrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CwePageHeader = _resolveComponent("CwePageHeader")!
  const _component_password_input = _resolveComponent("password-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CwePageHeader, { authenticationRequired: true }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t('change_password')), 1),
        (_ctx.passwordExpired)
          ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.$t('expired_reset_password_instructions')), 1))
          : (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.$t('forgot_reset_your_password_instructions')), 1)),
        (_ctx.errors.pageErrorMessage)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.errors.pageErrorMessage), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("form", {
          onSumbit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitHandler && _ctx.submitHandler(...args)), ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t('redeem_invite_password')), 1)
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_password_input, {
                modelValue: _ctx.password,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.password) = $event)),
                id: "password",
                onKeypress: _withKeys(_withModifiers(_ctx.submitHandler, ["prevent"]), ["enter"]),
                onBlur: _ctx.onBeforeSubmit
              }, null, 8, ["modelValue", "onKeypress", "onBlur"])
            ]),
            (!_ctx.errors.isMinLength)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.lengthError()), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("label", _hoisted_15, _toDisplayString(_ctx.$t('redeem_invite_confirm_password')), 1)
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_password_input, {
                modelValue: _ctx.confirmPassword,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.confirmPassword) = $event)),
                id: "confirmPassword",
                onKeypress: _withKeys(_withModifiers(_ctx.submitHandler, ["prevent"]), ["enter"]),
                onBlur: _ctx.onBeforeSubmit
              }, null, 8, ["modelValue", "onKeypress", "onBlur"])
            ]),
            (_ctx.errors.doesNotMatch)
              ? (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(_ctx.matchError()), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("button", {
              class: "button",
              type: "button",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.submitHandler && _ctx.submitHandler(...args)))
            }, _toDisplayString(_ctx.$t('redeem_invite_save_password_button')), 1)
          ])
        ], 32)
      ])
    ])
  ]))
}